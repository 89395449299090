.modelCard {
    display: flex;
    border: 3px solid green;
    border-radius: 25px;
    background-color: gray;
    margin: 1rem;
    width: 200px;
    height: 100px;
    align-items: center;
    justify-content: center;
}