.home-page {
    margin: 0 auto;
    max-width: 1200px;
    padding: 50px;
    width: 100%;
}

.hero-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 50px;
}

.hero-section h1 {
    font-size: 48px;
    margin-bottom: 20px;
}

.hero-section p {
    font-size: 24px;
    margin-bottom: 40px;
}

.explore-button {
    display: inline-block;
    background-color: #1e90ff;
    color: #fff;
    font-size: 18px;
    padding: 10px 20px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.explore-button:hover {
    background-color: #0066cc;
}

.card-link {
    position: flex;
    text-decoration: none;
}

.car-info-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background-color: #e1e1e1;
    border: 1px solid #e8e8e8;
    color: gray;
    padding: 2rem;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.car-info-card:hover {
    background-color: #c0bfbf;
    color: rgb(95, 93, 93);
}

.car-info-card h3 {
    font-size: 1.5rem;
}

.car-info-card p {
    font-size: 1.1rem;
}

.featured-section {
    margin-bottom: 50px;
    align-items: center;
}

.featured-section h2 {
    font-size: 36px;
    margin-bottom: 30px;
}

.car-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;
}

@media (max-width: 500px) {
    .car-grid {
        display: grid;
        grid-template-columns: repeat(1, 3fr);
        width: 80%;
    }
}

.info-section {
margin-bottom: 50px;
}

.info-section h2 {
    font-size: 36px;
    margin-bottom: 30px;
}

.info-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    margin-top: 2rem;
  }

.info-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background-color: #f8f8f8;
    border: 1px solid #e8e8e8;
    color: gray;
    padding: 2rem;
    border-radius: 5px;
    text-decoration: none;
}

.info-card i {
    font-size: 3rem;
    color: #444;
    margin-bottom: 1rem;
}

.info-card h3 {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 1rem;
    text-decoration: none;
}

.info-card p {
    text-decoration:none;
    font-size: 1.1rem;
    color: #666;
    text-decoration: none;
}

.cta-section {
    text-align: center;
    padding: 4rem;
    margin: 2rem;
    background-color: #f8f8f8;
    color: gray;
}

@media (max-width: 500px) {
    .cta-section {
        padding: 1.5rem;
        width: 80%;
    }
}

.cta-section h2 {
    font-size: 2.5rem;
    margin-bottom: 2rem;
}

@media (max-width: 500px) {
    .cta-section h2 {
        font-size: 1.5rem;
        margin-bottom: 2rem;
    }
}

.cta-section p {
    font-size: 1.1rem;
    margin-bottom: 2rem;
}

@media (max-width: 500px) {
    .cta-section p {
        font-size: 1.1rem;
        margin-bottom: 2rem;
    }
}

.cta-section .explore-button {
    font-size: 1.2rem;
    padding: 1rem 2rem;
    border-radius: 5px;
    background-color: #007bff;
    color: #fff;
    text-decoration: none;
    transition: all 0.2s ease-in-out;
}

.cta-section .explore-button:hover {
    background-color: #0062cc;
    color: #fff;
    transition: all 0.2s ease-in-out;
}

.contact-section {

}

.contact-card-img {
    transform: scale(1);
    transition: transform 0.5s;
}
.contact-card-img:hover {
    transform: scale(1.2);
}

.searchbar {
    left: 40%;
}
