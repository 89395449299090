.chart-container {
    width: 50vw;
    height: 30vh;
    margin: 0.5rem;
    padding: 2% 0% 0%;
    align-items: center;
    justify-content: center;
  }

@media (max-height: 400px) and (orientation: landscape) {
  .chart-container {
    height: 35vh;
  }
}

@media (max-width: 450px) and (orientation: portrait) {
  .chart-container {
    width: 90vw;
  }
}